<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸最新算法全面上线中泰证券 XTP
              </p>
              <div class="fst-italic mb-2">2022年11月9日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >中泰证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/20/01-AIWAP.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸智能算法最新版本正式上线中泰证券XTP，助力算法生态圈快速发展。在此之前，非凸科技与中泰证券已在算法交易方面进行了深度合作，算法能力得到了高度认可。
                  </p>
                   <p>
                    随着机构客户的不断发展壮大，机构经纪业务中对算法交易的需求越来越旺盛，对冲击成本、执行效率的要求也越来越高。非凸科技积极响应市场动态及升级需求，持续迭代算法策略，凭借优秀的性能和极致的技术服务，实现最新算法的顺利上线，从而助力机构业务增长。
                  </p>
                  <p>
                    非凸智能算法融合先进的量化模型，结合机器学习、深度学习等人工智能技术，拥有高胜率的量价预判能力，并通过Rust架构保证交易系统极速和稳定，为用户提高净收益曲线创造了条件。
                  </p>
                  <p>
                    除了减少交易成本、人力成本、人工误差、行情冲击等优势外，非凸智能算法依靠领先的长周期预测能力和独特的交易策略，使得算法交易的绩效能够跑赢TWAP/VWAP基准3-5BP，明显领先于市场平均0-1BP的水平。
                  </p>
                  <p>
                    非凸科技拥有百人专业团队，使用Rust全栈开发，极大地提高了研发效能，安全性和稳定性得到了双重保证。与此同时，千万级硬件投入，自建数据中心，实现了模型和策略的高效迭代，保证了算法的持续有效。
                  </p>
                  <p>
                    算法交易是金融和科技充分结合的典型场景。在这一场景下，非凸科技以其独有的算法策略，利用智能技术，长期稳定战胜市场均价，大幅降低市场冲击成本，为投资者获取交易环节超额收益。
                  </p>
                 <p>
                    此次，非凸最新算法上线中泰证券XTP，是源于双方对机构服务的极致追求，也是对算法交易未来的持续探索！
                  </p>
                  <p>
                    可咨询中泰证券营业部或非凸科技商务部，申请试用！
                  </p>
                   <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/20/02-上线XTP.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News20",
};
</script>

<style></style>
